.container {
  background: #f0f9fe;
  padding: 30px 15px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  /* direction: rtl; */
}

.form {
  width: 100%;
  padding: 0px 220px;
}

.splitTwoInputs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  margin-top: 10px;
}

.dropDownMenus {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto;
  width: 100%;
  gap: 10px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.button {
  height: 60px !important;
  padding: 25px !important;
  text-align: center !important;
  background-color: #6246c7 !important;
  color: white !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  letter-spacing: 2px !important;
  text-transform: capitalize !important;
}

.button:disabled {
  color: white !important;
  background-color: #3700ff75 !important;
}

.phoneInput {
  width: 100%;
}

input[name="phone"] {
  padding: 18px 3px;
  margin-bottom: 8px;
}

.captcha {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Mobile Version */
@media screen and (max-width: 575px) {
  .form {
    width: 100%;
    padding: 0px 30px;
  }

  .splitTwoInputs {
    flex-direction: column;
  }

  .dropDownMenus {
    display: flex;
    flex-direction: column;
  }
  .button {
    width: 100px !important;
    max-width: 100px !important;
  }
}

/* Tablet Version */
@media screen and (max-width: 850px) {
  .form {
    width: 100%;
    padding: 0px 30px;
  }

  .splitTwoInputs {
    flex-direction: column;
  }

  .dropDownMenus {
    display: flex;
    flex-direction: column;
  }
  .button {
    width: 150px !important;
    max-width: 150px !important;
  }
}

/* SplitTwoInputs */
@media screen and (max-width: 2000px) {
  .splitTwoInputs {
    flex-direction: column;
  }
  .resDate {
    margin-top: 15px;
  }
  .button {
    width: 220px !important;
    max-width: 220px !important;
  }
}
