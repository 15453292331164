
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;500;700&family=Climate+Crisis&family=Poppins:wght@400;500;600;700&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;1,300;1,600&family=Roboto:ital,wght@0,300;1,400&family=Work+Sans&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
 
  font-family: 'Cairo', sans-serif;
  font-family: 'Climate Crisis', cursive;
  font-family: 'Poppins', sans-serif;
  font-family: 'Raleway', sans-serif;
  font-family: 'Roboto', sans-serif;
  font-family: 'Work Sans', sans-serif;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  text-decoration: none;
  
}
.jKLQUD{
  background-color: #e7eaec !important;
}

