.english-select .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  right: 10px !important;
  left: auto;
}

.english-select .css-bpeome-MuiSvgIcon-root-MuiSelect-icon {
  right: 10px !important;
  left: auto;
}

.arabic-select .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  left: 10px;
  right: auto;
}

.arabic-select .css-bpeome-MuiSvgIcon-root-MuiSelect-icon {
  left: 10px !important;
  right: auto;
}

.arabic-select .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    padding-right: 14px !important;
}

.english-select .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    padding-right: 14px !important;
}