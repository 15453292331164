.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input {
  padding: 10px 20px !important;
  display: flex !important;
}

.english-select .css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
  right: 10px;
  left: auto;
}

.english-select .css-1mf6u8l-MuiSvgIcon-root-MuiSelect-icon {
  right: 10px;
  left: auto;
}

.arabic-select .css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
  right: 10px;
  left: auto;
}

.arabic-select .css-1mf6u8l-MuiSvgIcon-root-MuiSelect-icon {
  right: 10px;
  left: auto;
}
