/* Mobile Version */
@media screen and (max-width: 575px)  {
  .sc-iwsKbI {
    width: 250px !important;
    height: 520px !important;
    left: 25px !important;
    top: 10px !important;
  }
}

@media screen and (max-height: 500px)   {
    .sc-iwsKbI {
        width: 50vw !important;
        height: 60vh !important;
        left: 25px !important;
        top: 10px !important;
        overflow-y: scroll !important;

    }
}
