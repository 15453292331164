.arabic-select,
.english-select {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #0000003b;
  border-radius: 4px;
  position: relative;
  margin-top: 5px;
}

.react-datepicker-wrapper {
  flex: 2;
}

.react-datepicker__input-container input {
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
}

.arabic-select .react-datepicker__input-container input {
  padding: 19.5px 40px 19.5px 10px;
  direction: rtl;
}
.english-select .react-datepicker__input-container input {
  padding: 19.5px 40px 19.5px 10px;
  direction: ltr;
}

.my-calender {
  position: absolute;
  z-index: 1000;
}

.arabic-select .my-calender {
  right: 90%;
  left: auto;
}
.english-select .my-calender {
  right: 8px;
  left: auto;
}

.react-datepicker-wrapper .react-datepicker__input-container input {
  font-size: 18px !important;
}


.react-datepicker-popper {
  position: absolute;
  inset: initial !important; 
  transform: none !important;
  top: 60px !important;
  margin-left: -35%;
  margin-right: -35%;

}

@media(max-width:767px){
  .react-datepicker-popper {
    position: absolute;
    inset: initial !important; 
    transform: none !important;
    top: 60px !important;
margin: 10px -80%;
  
  }
}


/* .testCalender{
  background-color: red !important;
} */

/* .react-datepicker-popper{
  position: relative !important;

} */


