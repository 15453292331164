.chatBotIconContainer {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  position: fixed;
  bottom: 48px;
  right: 32px;
  background-color: white;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  line-height: 50%;
}

.chatBotIcon {
  color: #6345c7;
  position: absolute;
  top: 12px;
  left: 10px;
  cursor: pointer;
  font-size: 40px !important;
}

@media screen and (max-width: 575px) {
  .chatBotIconContainer {
    height: 40px;
    width: 40px;
  }
  .chatBotIcon {
    font-size: 30px !important;
    top: 6px;
    left: 6px;
  }
}

.chatBot {
  position: fixed;
  right: 69px;
  float: right;
  bottom: 114px;
  z-index: 5000;
}

.showAppointments ul,
.services ul {
  list-style: none;
  font-size: larger;
  margin-top: 20px;
}
.showAppointments ul li,
.services ul li {
  margin-top: 10px;
  transition: all 0.3s ease-in-out;
}

.showAppointments ul li:hover,
.services ul li:hover {
  padding-left: 5px;
}

.openingHours h4 {
  margin-bottom: 10px;
}
